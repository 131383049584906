import React from 'react';
import './Offers.css';
import exclusive_image from '../assets/exclusive_image.png';

const Offers = () => {
    return ( 
    <div className='offers'>
        <div className="offers-left">
            <h1>Exclusive</h1>
            <h1>offert pour vous</h1>
            <p>seulement parmis les meilleurs ventes</p>
            <button>Acheter maintenant</button>
        </div>
        <div className="offers-right">
            <img src={exclusive_image} alt="" />
        </div>
    </div> );
}

export default Offers ;