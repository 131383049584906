import React, { useEffect, useState } from 'react';
import './RelatedProducts.css';
// import data_product from '../assets/data'
import Item from '../item/Item';
import { config } from '../../Constants.js';

const RelatedProducts = (props) => {
    const {product}= props
    const [data_product, setData_Product] = useState([])

    useEffect(()=> {
      fetch(`${config.url}/produit/similaire`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'category': product.category, 'id': product.id})
      }).then((res)=> res.json()).then((data)=> setData_Product(data))
    },[])


    return ( 
    <div className='relatedproducts'>
        <h1>Produits similaires</h1>
        <hr />
        <div className="relatedproducts-item">
            {data_product.map((item,i)=>{
                return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
            })}
        </div>
    </div> );
}

export default RelatedProducts ;