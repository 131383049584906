import React from 'react';
import './Footer.css';
import instagram_icon from '../assets/instagram_icon.png';
import whatsapp_icon from '../assets/whatsapp_icon.png';
import pinterest_icon from '../assets/pintester_icon.png';
import logo from '../assets/31818468_7884396.png';

const Footer = () => {
    return ( 
    <div className='footer'>
        <div className="footer-logo">
            <img src={logo} alt="" />
            <p>BYAB</p>
        </div>
        <ul className='footer-links'>
            <li>Entreprise</li>
            <li>Produits</li>
            <li>A propos</li>
            <li>Contact</li>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <img src={instagram_icon} alt="" />
            </div>
            <div className="footer-icons-container">
                <img src={pinterest_icon} alt="" />
            </div>
            <div className="footer-icons-container">
                <img src={whatsapp_icon} alt="" />
            </div>
        </div>
        <div className="footer-copyright">
            <hr />
            <p>Copyright @ 2024 - Tout droits réservés.</p>
        </div>
    </div> );
}

export default Footer ;