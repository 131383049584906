import React from 'react';
import './NewsLetter.css';


const NewsLetter = () => {
    return ( 
    <div className='newsletter'>
        <h1>Obtenez l'exclusité dans votre messagerie</h1>
        <p>Souscrivez à notre newsletter et restez informé</p>
        <div>
            <input type="email" placeholder='Votre adresse email' />
            <button>Souscrire</button>
        </div>
    </div> );
}

export default NewsLetter ;