import React from 'react';
import arrow_icon from '../assets/breadcrum_arrow.png'
import './Breadcrum.css';


const Breadcrum = (props) => {
    const {product} = props
    // console.log(product)

    return ( 
    <div className='breadcrum'>
        ACCUEIL <img src={arrow_icon} alt="" /> SHOP <img src={arrow_icon} alt="" /> {product.category} <img src={arrow_icon} alt="" /> {product.name}
    </div> );
}

export default Breadcrum ;