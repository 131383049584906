import React, { useContext } from 'react';
import { ShopContext } from '../context/ShopContext';
import remove_icon from '../assets/cart_cross_icon.png'
import './CartItems.css';


const CartItems = () => {
    const {getTotalCartAmount, all_product, removeFromCart, cartItems}= useContext(ShopContext);
    return ( 
    <div className='cartitems'>
        <div className="cartitems-format-main">
            <p>Produits</p>
            <p>Titre</p>
            <p>Prix</p>
            <p>Quantité</p>
            <p>Total</p>
            <p>Suppriler</p>
        </div>
        <hr />
        {all_product.map((e, i)=> {
            if (cartItems[e.id] > 0) {
                return <div key={i}>
                <div className="cartitems-format cartitems-format-main">
                    <img src={e.image} className='carticon-product-icon' alt="" />
                    <p>{e.name}</p>
                    <p>${e.new_price}</p>
                    <button className='cartitems-quantity'>{cartItems[e.id]}</button>
                    <p>${e.new_price*cartItems[e.id]}</p>
                    <img className='cartitems-remove-icon' src={remove_icon} onClick={()=> removeFromCart(e.id)} alt="" />
                </div>
                <hr />
            </div>
            }

            return null;
        })}

        <div className="cartitems-down">
            <div className="cartitems-total">
                <h1>cart Total</h1>
                <div>
                    <div className="cartitems-total-item">
                        <p>Sous-total</p>
                        <p>${getTotalCartAmount()}</p>
                    </div>
                    <hr />
                    <div className="cartitems-total-item">
                        <p>Livraison</p>
                        <p>Gratuit</p>
                    </div>
                    <hr />
                    <div className="cartitems-total-item">
                        <h3>Total</h3>
                        <h3>${getTotalCartAmount()}</h3>
                    </div>
                </div>
                <button>PASSEZ AU PAIEMENT</button>
            </div>
            <div className="cartitems-promocode">
                <p>Entrer votre code promo ici</p>
                <div className="cartitems-promobox">
                    <input type="text" placeholder='code promo' />
                    <button>Enregistrer</button>
                </div>
            </div>
        </div>
    </div> );
}

export default CartItems ;