import React, { useState } from 'react';
import './css/LoginSignup.css';
import { config } from '../Constants.js';

const LoginSignup = () => {
    const [state, setState] = useState('Connexion')

    const [formData, setFormData]= useState({
        name: '',
        email: '',
        password: ''
    })

    const handleChange = (e)=> {
        setFormData({...formData, [e.target.name]:e.target.value})
    }

    const login = async ()=> {
        let responseData;
        await fetch(`${config.url}/user/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)

        }).then((res)=> res.json()).then((data)=> responseData=data)

        // console.log(responseData)

        if (responseData.success) {
            localStorage.setItem('auth-token', responseData.token)
            window.location.replace('/')
        } else{
            alert(responseData.errors)
        }
    }

    const signup = async ()=> {
        let responseData;
        await fetch(`${config.url}/user/signup`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)

        }).then((res)=> res.json()).then((data)=> responseData=data)

        if (responseData.success) {
            window.location.replace('/login')
        } else{
            alert(responseData.errors)
        }
    }

    return ( 
    <div className='loginsignup'>
        <div className="loginsignup-container">
            <h1>{state}</h1>
            <div className="loginsignup-fields">
                {state == 'Inscription'? <input value={formData.name} onChange={handleChange} name='name' type="text" placeholder='Votre nom' />: <></>}
                <input value={formData.email} onChange={handleChange} name='email' type="email" placeholder='Votre adresse email' />
                <input value={formData.password} onChange={handleChange} name='password' type="password" placeholder='Votre mot de passe' />
            </div>
            <button onClick={()=> {state == 'Connexion' ? login(): signup()}}>Continuer</button>
            {state == 'Connexion' ? <p className='loginsignup-login'>Pas de compte ? <span onClick={()=> setState('Inscription')}>S'inscrire</span></p>: <p className='loginsignup-login'>Déjà un compte ? <span onClick={()=> setState('Connexion')}>Se connecter</span></p>}
            <div className="loginsignup-agree">
                <input type="checkbox" name='' id='' />
                <p>En continuant, vous acceptez les termes et conditions d'utilisations.</p>
            </div>
        </div>
    </div> );
}

export default LoginSignup ;