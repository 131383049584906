import React, { useContext, useRef, useState } from 'react';
import './Navbar.css'
import logo from '../assets/31818468_7884396.png';
import cart_icon from '../assets/cart_icon.png';
import nav_dropdown_icon from '../assets/pngwing.com.png';
import { Link } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext';

const Navbar =  ()=> {
    const [menu, setMenu]= useState('shop')
    const {getTotalCartItems}= useContext(ShopContext);
    const menuRef =  useRef()
    const dropdown_toggle = (e)=> {
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open');
    }
    return (
        <div className='navbar'>
            <Link to={'/'} className='nav-logo' style={{textDecoration: "none"}}>
                <img src={logo} alt="" />
                <p>BYAB</p>
            </Link>
            <img className='nav-dropdown' onClick={dropdown_toggle} src={nav_dropdown_icon} alt="" />
            <ul ref={menuRef} className='nav-menu'>
                <li onClick={()=> setMenu('shop')}><Link style={{textDecoration: 'none'}} to="/">Shop</Link> {menu === 'shop' ? <hr />: <></>}</li>
                <li onClick={()=> setMenu('men')}><Link style={{textDecoration: 'none'}} to="/men">Men</Link> {menu === 'men' ? <hr />: <></>}</li>
                <li onClick={()=> setMenu('women')}><Link style={{textDecoration: 'none'}} to="/women">Women</Link> {menu === 'women' ? <hr />: <></>}</li>
                <li onClick={()=> setMenu('kids')}><Link style={{textDecoration: 'none'}} to="/kids">Kids</Link> {menu === 'kids' ? <hr />: <></>}</li>
            </ul>
            <div className='nav-login-cart'>
                {localStorage.getItem('auth-token') ? <button onClick={()=> {localStorage.removeItem('auth-token'); window.location.replace('/')}}>Déconnexion</button>: <Link to='/login'><button>Connexion</button></Link>}
                
                <Link to='/cart'><img src={cart_icon} alt="" /></Link>
                <div className='nav-cart-count'>{getTotalCartItems()}</div>
            </div>
        </div>
    );
}

export default Navbar;