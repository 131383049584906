import React, { useContext } from 'react';
import { ShopContext } from '../components/context/ShopContext';
import { useParams } from 'react-router-dom';
import Breadcrum from '../components/breadcrum/Breadcrum';
import ProductDisplay from '../components/productDisplay/ProductDisplay';
import DescriptionBox from '../components/descriptionBox/DescriptionBox';
import RelatedProducts from '../components/relatedProduct/RelatedProducts';

const Product = () => {
    const {all_product}= useContext(ShopContext);
    const {productId}= useParams();
    const product = all_product.find((e)=> e.id == productId);

    // console.log(all_product, productId)
    


    return ( 
    <div>
        <Breadcrum product={product} />
        <ProductDisplay product={product} />
        <DescriptionBox  />
        <RelatedProducts product={product}  />
    </div> );
}

export default Product ;