import React, { useContext } from 'react';
import { ShopContext } from '../components/context/ShopContext';
import dropdown_icon from '../components/assets/dropdown_icon.png';
import Item from '../components/item/Item';

import './css/ShopCategory.css'

const ShopCategory = (props) => {
    const {all_product}= useContext(ShopContext)
    return ( 
    <div className='shop-category'>
        <img className='shopcategory-banner' src={props.banner} alt="" />
        <div className="shopcategory-indexSort">
            <p>
                <span>Voir 1-12</span> sur 100 produits
            </p>
            <div className="shopcategory-sort">
                trier par <img src={dropdown_icon} alt="" />
            </div>
        </div>
        <div className="shopcategory-products">
            {all_product.map((item,i)=> {
                if (props.category == item.category) {
                    return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
                }else{
                    return null;
                }
            })}
        </div>

        <div className="shopcategory-loadmore">
            Voir plus
        </div>
    </div> 
    );
}

export default ShopCategory ;