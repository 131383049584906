import React, { useEffect, useState } from 'react';
import './Popular.css';
// import data_product from '../assets/data';
import Item from '../item/Item';

import { config } from '../../Constants.js';

const Popular = () => {
    const [data_product, setData_Product] = useState([])

    useEffect(()=> {
      fetch(`${config.url}/produit/populaire-dans-femme`).then((res)=> res.json()).then((data)=> setData_Product(data))
    }, [])
    return ( 
    <div className='popular'>
        <h1>Populaire dans Femmes</h1>
        <hr />
        <div className="popular-item">
            {data_product.map((item , i)=> {
                return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
            })}
        </div>
    </div> );
}

export default Popular ;